

<template>
    <footer>
      <p>© 2023 par Zak. Tous droits réservés.</p>
    </footer>
  </template>

  <script>
  export default {
    name: "SiteFooter",
  }
  </script>

  <style scoped>
  footer {
    text-align: center;
    padding: 10px;
    background-color: rgb(166, 238, 202);
    position: fixed;
    left: 0;
    bottom: 0;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 100%;
    font-size: 13px;
  }
  </style>
