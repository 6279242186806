

<template>
  <div class="container mx-auto p-6">
    <div v-if="event" class="bg-white shadow-lg rounded-lg overflow-hidden">
      <img :src="event.imageUrl" alt="Image de l'événement" class="w-full h-auto object-cover"/>
      <div class="grid p-4">
        <h2 class="text-2xl font-bold mb-2">{{ event.title }}</h2>
        <p class="text-gray-800 mb-4">{{ event.description }}</p>
        <p class="text-gray-700 mb-2">Date : {{ event.date }}</p>
        <p class="font-bold mb-2">{{ event.location }}</p>
        <div class="flex justify-center items-center mt-4">
          <router-link :to="`/payment/${event.id}`" class="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded">Réserver</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: "EventDetail",
  data() {
    return {
      event: null
    };
  },
  created() {
    const eventId = this.$route.params.id;
    axios.get(`https://365events.viafro.com/api/events/${eventId}`)
        .then(response => {
            this.event = response.data;
        })
        .catch(error => {
            console.error("Erreur lors de la récupération de l'événement:", error);
        });
  }
};
</script>

<style scoped>

</style>
